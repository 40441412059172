import React, { Suspense, lazy } from 'react';
import './home.css';
import Header from '../../components/ui/Header/Header';

// Lazy loads these components in
const DevPortfolio = lazy(() => import('../../components/home/DevPortfolio/DevPortfolio'));
const HomeArticleContainer = lazy(() => import('../../components/home/HomeArticleContainer/HomeArticleContainer'));
const DesignPortfolio = lazy(() => import('../../components/home/DesignPortfolio/DesignPortfolio'));



function Home() {


    return (
        <>
            <Header
                title="Hi, I’m Parker, full-stack developer and creative."
                subTitleOne={`
                I am an experienced Software engineer with a demonstrated history of working in the computer software industry. Experienced with setting up and maintaining serverless architecture including GCS, AWS. Technical languages include: JAVA, PHP, HTML/CSS, Javascript, Mysql, Python, VueJS, React, Angular. Further strengths include successful experience with creating and maintaining APIs and ETLs for and from many platforms. Strong engineering professional with a MS in Computer Information Systems with a concentration in IT Project Management. Along with a double major from Bridgewater College in computer science and business administration.
                `
                }
                size='large'
            />

            <Suspense fallback={<div>Loading…</div>}>
                <DevPortfolio />
                <HomeArticleContainer />
                <DesignPortfolio />
            </Suspense>
        </>
    )
}


export default Home;
